

















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { cartGetters } from '@vsf-enterprise/commercetools';
import SideBar from '~/components/organisms/SideBar/SideBar.vue';
import { useCartExtended, useLineItems, useCartSidebars, useCartSidebarStore } from '~/composables';
import CartSummary from '~/components/organisms/Cart/CartSummary.vue';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SideBar,
    CartSummary
  },
  setup() {
    const { isCartSidebarOpen, toggleCartSidebar } = useCartSidebarStore();
    const { cart } = useCartExtended();
    const { handleGoToCheckout } = useCartSidebars();

    const { lineItems } = useLineItems();

    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const total = computed(() => getTotalGrossPrice(cart.value));

    const handleClickCheckout = () => {
      toggleCartSidebar();
      handleGoToCheckout();
    };
    return {
      isCartSidebarOpen,
      toggleCartSidebar,
      lineItems,
      handleClickCheckout,
      totalItems,
      total
    };
  }
});
