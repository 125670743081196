






















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import SummaryItem from '~/components/atoms/SummaryItem.vue';
import { MappedDiscount } from '~/types/discounts';

export default defineComponent({
  name: 'DiscountSummaryItems',
  components: {
    SummaryItem
  },
  props: {
    discounts: {
      type: Array as PropType<MappedDiscount[]>,
      default: () => [],
      validator: (discounts: MappedDiscount[]) => {
        return discounts.every((discount) => {
          return discount.id && discount.name && discount.discountedAmount;
        });
      }
    },
    readOnly: {
      type: Boolean,
      default: true
    }
  }
});
