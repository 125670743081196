













































import { SfAccordion } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import CartCoupon from '~/components/molecules/Cart/CartCoupon.vue';
export default defineComponent({
  name: 'CartSummaryCoupon',
  components: {
    CartCoupon,
    SfAccordion
  }
});
