








































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import Link from '~/components/atoms/Link/Link.vue';
import TwicpicsImage from '~/components/atoms/TwicpicsImage/TwicpicsImage.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import type { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';
import { SIMPLIFIED_CARD } from '~/constants/productCard';
import { useIntegrations, useRecommendationEvent } from '~/composables';
import type { ProductCategories } from '~/types/product/ProductCategories';
import { RECOMMENDATION_ACTIONS } from '~/constants/recommendationRequest';
import { BLOOMREACH_TYPE_NAME } from '~/types/localization/localizationHelperTypes';

export default defineComponent({
  name: 'MiniProductCard',
  components: {
    Link,
    TwicpicsImage,
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue')
  },
  props: {
    link: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    productBoxTypeTag: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      default: (): EnergyRatingDetails => ({})
    },
    sku: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    discountedPrice: {
      type: Number,
      default: null
    },
    brand: {
      type: String,
      default: ''
    },
    categories: {
      type: [Object, String] as PropType<ProductCategories>,
      required: true
    }
  },
  setup(props, { emit }) {
    const showEnergyRating = computed(() => {
      return !!props.energyRating?.rating;
    });

    const { $tagManager } = useIntegrations();

    const { triggerCiaRecommendationEvent } =
      useRecommendationEvent({
        path: props.link,
        recommendationType: BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION
      });

    const isDiscounted = computed(() => {
      return props.discountedPrice > 0;
    });

    const handleCardClick = () => {
      emit('product-clicked');
      $tagManager.events.triggerClickProductBoxTags({
        boxType: props.productBoxTypeTag,
        product: {
          id: props.sku,
          name: props.title,
          currency: props.currency,
          discountedPrice: props.discountedPrice,
          brand: props.brand,
          price: props.price,
          categories: props.categories
        }
      });
      triggerCiaRecommendationEvent(RECOMMENDATION_ACTIONS.CLICK);
    };

    return {
      LinkType,
      showEnergyRating,
      ENERGY_RATING_SIZE,
      SIMPLIFIED_CARD,
      handleCardClick,
      isDiscounted,
      triggerCiaRecommendationEvent
    };
  }
});
