

























































































import { debounce } from 'lodash';
import { defineComponent, computed, onMounted, watch, nextTick, ref } from '@nuxtjs/composition-api';
import type { LineItem, ProductVariant } from '@vsf-enterprise/commercetools-types';
import { useVSFContext } from '@vue-storefront/core';
import ProductCardVertical from '~/components/organisms/Product/ProductCardVertical/ProductCardVertical.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import SummaryItem from '~/components/atoms/SummaryItem.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import AddExtraGuarantee from '~/components/molecules/ExtraGuarantee/AddExtraGuarantee/AddExtraGuarantee.vue';
import {
  useShippingCost,
  useI18n,
  useCartExtended,
  useShippingMethods,
  useLineItems,
  useExtraGuarantee,
  useRemoveFromCartTagManager
} from '~/composables';
import Link from '~/components/atoms/Link/Link.vue';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import getDeliveryDate from '~/helpers/getDeliveryDate';
import { ShippingMethodDeliveryInformation } from '~/types/checkout/ShippingMethod';
import {
  getCartDiscounts,
  getDiscountCodeByCouponId
} from '~/helpers/cart/cartDiscountGetter';
import DiscountSummaryItems from '~/components/molecules/Checkout/DiscountSummaryItems/DiscountSummaryItems.vue';
import CartSummaryCoupon from '~/components/molecules/Cart/CartSummaryCoupon.vue';
import { MappedDiscount } from '~/types/discounts';
import { GUARANTEE_PLACEMENT } from '~/constants/guaranteePlacement';
import { STANDARD_PRODUCT, EXTRA_GUARANTEE } from '~/constants/productType';
import type { RemoveFromCartTagDetailsParams } from '~/types/integrations/tagManager/TagManagerIntegration';
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';
import BloomreachCartRecommendations
  from '~/components/organisms/Product/Bloomreach/BloomreachCartRecommendations/BloomreachCartRecommendations.vue';
import CartSidebarHeading from '~/components/atoms/CartSidebars/CartSidebarHeading.vue';

export default defineComponent({
  name: 'CartSummary',

  components: {
    CartSidebarHeading,
    BloomreachCartRecommendations,
    CartDiscountWarning: () => import(/* webpackChunkName: "CartDiscountWarning" */
      '~/components/molecules/Cart/CartDiscountWarning/CartDiscountWarning.vue'),
    ProductCardVertical,
    SummaryItem,
    Button,
    Link,
    AddExtraGuarantee,
    DiscountSummaryItems,
    CartSummaryCoupon
  },

  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { shippingCostLabel, shippingCost } = useShippingCost();
    const { i18n } = useVSFContext();
    const { languageAndCountry } = useI18n();
    const { selectedShippingMethod } = useShippingMethods();
    const { triggerTags: triggerTagsRemoveFromCart } = useRemoveFromCartTagManager();
    const discounts = computed(() => getCartDiscounts(cart.value));
    const cartSummaryRef = ref<HTMLElement>(null);

    const {
      cart,
      updateQuantity,
      removeItem,
      removeCoupon
    } = useCartExtended();
    const { sendCiaViewGuarantee } = useExtraGuarantee();

    onMounted(() => {
      sendCiaViewGuarantee(GUARANTEE_PLACEMENT.CART);
    });

    const { lineItems, loadVolumeDiscounts } = useLineItems();
    const MAX_ROWS = 4;

    const maxVisibleRows = ref(MAX_ROWS);

    const setMaxVisibleRows = (maxNumberOfRows: number): void => {
      maxVisibleRows.value = maxNumberOfRows;
    };

    const removeItemFromCart = async (productLineItem: LineItem, orderInCart: number) => {
      await removeItem({ product: productLineItem });
      const product: ProductVariant = {
        ...productLineItem.variant,
        price: productLineItem.price
      };

      const details: RemoveFromCartTagDetailsParams = {
        quantity: productLineItem.quantity,
        textDisplayed: i18n.t('Remove').toString(),
        triggeredIn: EVENT_SOURCE.CART_SIDEBAR,
        index: orderInCart
      };

      triggerTagsRemoveFromCart(product, details);
    };

    const deleteCoupon = (couponId: MappedDiscount['id']) => {
      const couponCode = getDiscountCodeByCouponId(cart.value, couponId);
      removeCoupon({
        couponCode,
        customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM
      });
    };

    const modalDescription = computed<string>(() => props.totalItems && !shippingCost.value
      ? i18n.t('You qualify for FREE shipping').toString()
      : '');

    const debouncedInputQuantityHandler = debounce(function(product, event) {
      updateQuantity(product, event);
    }, DEBOUNCE_TIMES.NUMBER_INPUT);

    const deliveryDate = computed(() =>
      getDeliveryDate(languageAndCountry.value, selectedShippingMethod.value as ShippingMethodDeliveryInformation)
    );

    const approximateDeliveryDate = computed(() =>
      i18n.t('approx. Delivery date', { deliveryDate: deliveryDate.value })
    );

    const shippingLabel = computed(() => deliveryDate.value
      ? `${i18n.t('Delivery')} ${approximateDeliveryDate.value}`
      : i18n.t('Shipping')
    );

    const cartSummaryHeading = computed(() =>
      i18n.t('Your order summary')
    );

    const countItemsOfType = (itemName: string) => {
      const productKeys = lineItems.value.map(item => item.productType?.key);
      return productKeys.reduce((count: number, productType) =>
        productType === itemName ? count + 1 : count, 0);
    };
    const extraGuaranteeInactiveCount = computed(
      () => countItemsOfType(STANDARD_PRODUCT) - countItemsOfType(EXTRA_GUARANTEE)
    );
    const numberOfAllRowsInCart = computed(() =>
      countItemsOfType(STANDARD_PRODUCT) + countItemsOfType(EXTRA_GUARANTEE) + extraGuaranteeInactiveCount.value
    );
    const handleLineItemRows = () => {
      if (cartSummaryRef.value && lineItems.value) {
        cartSummaryRef.value.querySelectorAll('[data-row]').forEach((el, index) => {
          if (index >= maxVisibleRows.value) {
            el.classList.add('hidden');
          } else {
            el.classList.remove('hidden');
          }
        });
      }
    };

    const showAllLineItemRows = () => {
      setMaxVisibleRows(numberOfAllRowsInCart.value);
      cartSummaryRef.value.querySelectorAll('[data-row]').forEach((el) => {
        el.classList.remove('hidden');
      });
    };

    watch(lineItems, async() => {
      // Please remove the nextTick after implementing TRAN-2011
      // (https://expondo.atlassian.net/browse/TRAN-2011)
      await nextTick();
      handleLineItemRows();
    }, { immediate: true });

    const lineItemsLength = computed(() => !!lineItems.value?.length);

    watch(lineItemsLength, () => {
      loadVolumeDiscounts();
    });

    return {
      MAX_ROWS,
      LinkType,
      shippingCostLabel,
      modalDescription,
      updateQuantity,
      removeItemFromCart,
      debouncedInputQuantityHandler,
      shippingLabel,
      lineItems,
      discounts,
      cartSummaryHeading,
      deleteCoupon,
      extraGuaranteeInactiveCount,
      numberOfAllRowsInCart,
      cartSummaryRef,
      showAllLineItemRows,
      maxVisibleRows,
      EVENT_SOURCE
    };
  }
});
